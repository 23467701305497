// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-site-js": () => import("./../../../src/pages/about-site.js" /* webpackChunkName: "component---src-pages-about-site-js" */),
  "component---src-pages-all-news-js": () => import("./../../../src/pages/all-news.js" /* webpackChunkName: "component---src-pages-all-news-js" */),
  "component---src-pages-all-posts-js": () => import("./../../../src/pages/all-posts.js" /* webpackChunkName: "component---src-pages-all-posts-js" */),
  "component---src-pages-all-products-js": () => import("./../../../src/pages/all-products.js" /* webpackChunkName: "component---src-pages-all-products-js" */),
  "component---src-pages-chatbot-js": () => import("./../../../src/pages/chatbot.js" /* webpackChunkName: "component---src-pages-chatbot-js" */),
  "component---src-pages-html-policy-js": () => import("./../../../src/pages/html-policy.js" /* webpackChunkName: "component---src-pages-html-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-razor-x-privacy-js": () => import("./../../../src/pages/razor-x-privacy.js" /* webpackChunkName: "component---src-pages-razor-x-privacy-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-wallpaper-policy-js": () => import("./../../../src/pages/wallpaper-policy.js" /* webpackChunkName: "component---src-pages-wallpaper-policy-js" */),
  "component---src-templates-md-page-js": () => import("./../../../src/templates/md-Page.js" /* webpackChunkName: "component---src-templates-md-page-js" */)
}

